<template>
  <page-header-wrapper class="month-discount" :title='this.$route.meta.title'>
    <a-tabs default-active-key="1" :tabBarStyle="{background: '#fff'}">
      <a-tab-pane key="1" tab="管理积分">
        <manager-list @setOrderTitle="setManagerTitle"></manager-list>
      </a-tab-pane>
      <a-tab-pane key="2" tab="服务积分">
        <server-list @setServerTitle="setServerTitle"></server-list>
      </a-tab-pane>
    </a-tabs>
  </page-header-wrapper>
</template>

<script>
import moment from "moment";
import ManagerList from "@/views/points/manager/ManagerList";
import ServerList from "@/views/points/manager/ServerList";

const formatType = 'YYYY-MM-DD HH:mm';
const baseStart = moment().startOf('month').format(formatType);
const baseEnd = moment().format(formatType);

export default {
  name: 'ManagerLists',
  components: {
    ManagerList,ServerList,
  },
  data() {
    return {
      managerTotal: 0,
      serverTotal: -1,
    }
  },
  created() {
    this.handleInitial();
  },
  methods: {
    setManagerTitle(value) {
      this.managerTotal = value
    },

    setServerTitle(value) {
      this.serverTotal = value
    },
    handleInitial(){


    }

  },
}
</script>
<style lang="less">
.month-discount {
  .ant-tabs-nav-wrap {
    padding: 0 26px;
  }
  .ant-tabs-bar {
    margin: unset !important;
  }
  >.ant-pro-grid-content>.ant-pro-page-header-wrap-children-content {
    margin: unset !important;
  }
}
</style>
